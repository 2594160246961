document.addEventListener("DOMContentLoaded", function() {
  const $macButton = $(".download-buttons .button.mac");
  const $windowsButton = $(".download-buttons .button.windows");
  const $downloadModal = $(".modal.download-modal");
  const $downloadModalForm = $downloadModal.find("form");
  const $modalAgreeInput = $downloadModal.find("[name=agree_to_terms]");
  const $modalSubmit = $downloadModal.find("[type=submit]");

  $macButton.click(function() {
    $downloadModal.modal('show');
    $downloadModal.find("[name=operating_system]").val('mac');
  });

  $windowsButton.click(function() {
    $downloadModal.modal('show');
    $downloadModal.find("[name=operating_system]").val('windows');
  });

  $downloadModalForm.submit(function(e) {
    const agreed = $modalAgreeInput.is(':checked');

    if (agreed) return;

    e.preventDefault();

    alert("Sorry, you cannot download the application if you do not agree to the terms of use");
  });

  $modalAgreeInput.change(function() {
    const agreed = $modalAgreeInput.is(':checked');
    $modalSubmit.prop('disabled', !agreed);
  });

  $downloadModal.on('hidden.bs.modal', function (e) {
    $modalAgreeInput.prop('checked', false);
    $modalSubmit.prop('disabled', true);
  })
});
